<template>
 <div class="view">
   <Flv class-name="video" ref="video" class="first" vlabel="第一视角" :full="true" :rtcUrl="source" :source="source" :is-a-rtc="isARtc" />

 </div>
</template>

<script>
import Flv from '@/components/Player/Flv.vue';
export default {
  name: 'webrtcView',
  components: {
    Flv,
  },

  data() {
    return {
      isARtc:false,
      source:''
    }
  },
  created() {
    let urlhref =decodeURIComponent(window.location.href)
    let isARtc= urlhref.split('isARtc=')[1];
    
    let source =(urlhref.split('source=')[1].split('&')[0]) 
    if(isARtc=='1'){
     this.isARtc=true
   }else{
    this.isARtc=false
   }
    this.source=source
  },
  methods: {
  
  }
};
</script>

<style lang="scss" scoped>
.view{
  width: 100%;
  height: 100%;
  background: red;
  .video{
     width: 100%;
      height: 100%;
  }

}

</style>
